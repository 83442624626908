<template>
    <div>
        <div class="container">
            <!-- Application Dashboard -->
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="card card-default" v-if="resource.id">
                        <div class="card-body bg-white">
                            <div class="row">
                                <div class="col">
                                    <a :href="'/resources'" class="btn btn-outline-primary"><i class="fa fa-arrow-left mr-2"></i>Back to Resources</a>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <h5 class="">Resource: {{resource.resource.title}}</h5>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-3 mb-3">
                                    <div class="card border-0">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col text-center">
                                                    <h6 class="mb-0 text-primary wrap-ellipses">{{ resource.resource.title }}</h6>
                                                    <p v-if="resource.resource.model === 'UrlResource'" class="mb-0 bg-light mt-3 p-2"><i class="fas fa-link" style="font-size: 36px;"></i></p>
                                                    <p v-if="resource.resource.model === 'FileResource'" class="mb-0 bg-light mt-3 p-2"><i class="fas fa-file" style="font-size: 36px;"></i></p>
                                                    <p v-if="resource.resource.model === 'LegalResource'" class="mb-0 bg-light mt-3 p-2"><i class="fas fa-file-contract" style="font-size: 36px;"></i></p>
                                                    <button v-if="resource.resource.model === 'LegalResource'"
                                                            @click="viewLegalResource(resource)"
                                                            class="btn btn-sm btn-outline-primary mt-2 w-100">
                                                        <i class="fa fa-eye mr-2"></i>View
                                                    </button>
                                                    <button v-if="resource.resource.model === 'FileResource'"
                                                            @click="downloadResource(resource)"
                                                            class="btn btn-sm btn-outline-primary mt-2 w-100">
                                                        <i class="fa fa-download mr-2"></i>Download
                                                    </button>
                                                    <button v-if="resource.resource.model === 'UrlResource'"
                                                            @click="followResourceLink(resource)"
                                                            class="btn btn-sm btn-outline-primary mt-2 w-100">
                                                        <i class="fa fa-arrow-right mr-2"></i>Go to URL
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <h5 class="mb-3">Comments ({{resource.comments.length}})</h5>
                                </div>
                            </div>
                            <div v-for="comment in resource.comments">
                                <div class="row mt-2">
                                    <div class="col">
                                        <div class="resource-comment mb-2 row"
                                             :class="comment.commenter_model === 'User' ? 'practitioner-comment' : 'client-comment'">
                                            <div v-if="comment.commenter_model === 'User'"
                                                 class="comment-avatar col-md-1 col-sm-2 text-center pr-1 mr-3">
                                                <img class="mx-auto rounded-circle img-fluid" :src="resource.practitioner.profile_pic" alt="avatar">
                                            </div>
                                            <div class="comment-content col-md-8 col-sm-10">
                                                <h6 class="small comment-meta d-inline">{{ comment.created_at | prettyTime }}</h6>
                                                <span class="d-inline small badge badge-pill badge-primary ml-2" v-if="!comment.read_by_client">New</span>
                                                <div class="comment-body" v-html="comment.comment_text"></div>
                                            </div>
                                            <div v-if="comment.commenter_model === 'Client'"
                                                 class="comment-avatar col-md-1 col-sm-2 text-center pr-1 ml-3">
                                                <div class="avatar-circle mt-2">
                                                    <span class="initials-avatar">{{ resource.client.name | initials}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="row mt-4 mb-4" v-if="resource.comments.length === 0">
                                <div class="col">
                                    <p class="font-italic text-center">No comments yet. Add one below.</p>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col">
                                    <h5 class="">Add a Comment</h5>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <vue-editor v-model="comment"></vue-editor>
                                    <button class="btn btn-outline-primary mt-2" @click="addComment">Add Comment</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <view-document-modal v-if="viewDocument" :document="resource.resource.resource.document" @close="viewDocument = false"></view-document-modal>

    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import ViewDocumentModal from "@/views/globals/ViewDocumentModal";
    export default {
      props: [],
      data() {
        return {
            resource: [],
            comment: "",
            viewDocument: false,
        };
      },
      methods: {
          fetchResource() {
              this.$axios.get(process.env.VUE_APP_API_URL + "/client/resources/" + this.$route.params.id).then(({ data }) => {
                  this.resource = data.resource;
              });
          },
          markResourceAsReadByClient(){
              this.$axios
                  .post(process.env.VUE_APP_API_URL + "/client/resources/mark-read-by-client", {
                      client_resource_id: this.$route.params.id,
                  });
          },
          addComment(){
              let _this = this;
              this.$axios
                  .post(process.env.VUE_APP_API_URL + "/client/resources/comments", {
                      client_resource_id: this.$route.params.id,
                      comment: this.comment
                  })
                  .then(({ data }) => {
                      this.$EventBus.$emit("alert", data);
                      _this.comment = "";
                      this.fetchResource();
                  });
          },
          downloadResource(clientResource) {
              window.location.replace(clientResource.resource.resource.file);
          },
          followResourceLink(clientResource) {
              window.location.replace(clientResource.resource.resource.url);
          },
          viewLegalResource(clientResource) {
              if (clientResource.resource.resource.file) {
                  this.downloadResource(clientResource)
              } else if (clientResource.resource.resource.document) {
                  this.viewDocument = true;
              }
          }
      },
      mounted() {
          this.fetchResource();
          this.markResourceAsReadByClient();
      },
      filters: {
          initials(str) {
              let arr = str.split(" ");
              const first = arr[0].charAt(0).toUpperCase();
              const last = arr[arr.length - 1].charAt(0).toUpperCase();
              return first + last;
          },
          prettyTime(date) {
              return moment(date).fromNow();
          }
      },
      computed: {
        client() {
          return this.$store.user;
        }
      },
      components: {
        ViewDocumentModal,
          VueEditor
      },
    };
</script>

<style>
</style>
